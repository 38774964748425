<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { supabase } from './utils/supabase';

export default {
  name: 'App',
  data() {
    return {
      deviceTag: '',
      gotoParam: '',
      orderId: ''
    };
  },
  methods: {
    handleDeviceTagUpdated(event) {
      this.deviceTag = event.detail.device_tag;
      this.gotoParam = event.detail.goto_param;
      console.log('Device Tag Updated:', this.deviceTag, 'Goto Param:', this.gotoParam);
    },
    async handleOrderUpdated(event) {
      this.orderId = event.detail.order_id;
      console.log('Order ID Updated:', this.orderId);

      try {
        const { data, error } = await supabase
          .from('user_orders')
          .select('type')
          .eq('order_id', this.orderId)
          .single();

        if (error) {
          console.error('Error fetching order details:', error);
          return;
        }

        if (data) {
          if (data.type === 'TICKETS') {
            this.$router.push({ name: 'Billing' });
          } else if (data.type === 'MERCHANDISE') {
            this.$router.push({ name: 'MyPurchases' });
          } else {
            console.error('Unknown order type:', data.type);
          }
        } else {
          console.error('No order found with the specified order_id');
        }
      } catch (err) {
        console.error('Error handling order update:', err);
      }
    }
  },
  mounted() {
    window.addEventListener('deviceTagUpdated', this.handleDeviceTagUpdated);
    window.addEventListener('OrderUpdated', this.handleOrderUpdated);

    // Simulate calling the function for testing purposes
    // Uncomment the following lines to simulate the event for testing
    // const testEvent = new CustomEvent('OrderUpdated', { detail: { order_id: 'test-order-id' } });
    // window.dispatchEvent(testEvent);

    // Initialize auto ads if needed
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({
        google_ad_client: 'ca-pub-3262830980905959',
        enable_page_level_ads: true
      });
    } catch (error) {
      console.error('Auto ads error:', error);
    }
  },
  beforeUnmount() {
    window.removeEventListener('deviceTagUpdated', this.handleDeviceTagUpdated);
    window.removeEventListener('OrderUpdated', this.handleOrderUpdated);
  }
};
</script>

<style>
/* Add any styles if needed */
</style>
