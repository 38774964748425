<template>
  <div class="bg-gradient-to-br from-blue-100 to-purple-100 min-h-screen py-8">
    <div class="container mx-auto px-4 max-w-4xl">
      <div v-if="promo" class="bg-white rounded-3xl shadow-2xl overflow-hidden">
        <!-- Hero Section -->
        <div class="relative">
          <img :src="promo.thumbnail2" alt="Promo Hero" class="w-full h-80 sm:h-96 md:h-[28rem] object-cover">
          <div class="absolute inset-0 bg-gradient-to-t from-black to-transparent opacity-70"></div>
          <div class="absolute bottom-0 left-0 right-0 p-6 text-white">
            <h1 class="text-3xl sm:text-4xl md:text-5xl font-bold mb-2">{{ promo.title }}</h1>
            <p class="text-sm sm:text-base md:text-lg leading-snug">{{ promo.short_description }}</p>
          </div>
        </div>

        <!-- Content Section -->
        <div class="p-4 sm:p-6 md:p-8 space-y-4 sm:space-y-6 md:space-y-8">
          <!-- Event Details -->
          <div class="bg-gray-50 rounded-2xl p-4 sm:p-6 shadow-inner">
            <h2 class="text-xl sm:text-2xl font-bold mb-4 text-gray-800">Event Details</h2>
            <div class="space-y-2 sm:space-y-3">
              <div class="flex items-center text-gray-700">
                <CalendarIcon class="w-5 h-5 sm:w-6 sm:h-6 mr-2 sm:mr-3 text-blue-500"/>
                <span class="text-sm sm:text-lg">{{ formattedDateRange }}</span>
              </div>
              <div class="flex items-center text-gray-700">
                <MapPinIcon class="w-5 h-5 sm:w-6 sm:h-6 mr-2 sm:mr-3 text-red-500"/>
                <span class="text-sm sm:text-lg">{{ promo.address }}, {{ promo.city }}</span>
              </div>
              <div class="flex items-center text-gray-700">
                <ClockIcon class="w-5 h-5 sm:w-6 sm:h-6 mr-2 sm:mr-3 text-green-500"/>
                <span class="text-sm sm:text-lg">{{ promo.start_time }} - {{ promo.end_time }}</span>
              </div>
            </div>
          </div>

          <!-- How to Use PayNGo eTicket -->
          <div class="bg-gradient-to-r from-blue-50 to-purple-50 rounded-2xl p-4 sm:p-6 shadow-md">
            <h2 class="text-xl sm:text-2xl font-bold mb-4 text-gray-800">How to Use PayNGo eTicket</h2>
            <ol class="list-decimal list-inside text-gray-700 space-y-2 sm:space-y-2 pl-4">
              <li class="text-sm sm:text-lg">Download the PayNGo app from your app store.</li>
              <li class="text-sm sm:text-lg">Open the app and go to <b>e-Ticket</b>, then sign in or create a new account.</li>
              <li class="text-sm sm:text-lg">Navigate to the 'Events' section and find this event.</li>
              <li class="text-sm sm:text-lg">Select your desired ticket and complete the purchase process.</li>
              <li class="text-sm sm:text-lg">Your ticket will be available in the 'Tickets' section within the app.</li>
              <li class="text-sm sm:text-lg">Show your eTicket at the event entrance for quick and easy entry.</li>
            </ol>
          </div>

          <!-- Terms and Conditions -->
          <div class="bg-gray-50 rounded-2xl p-4 sm:p-6 shadow-inner">
            <h2 class="text-xl sm:text-2xl font-bold mb-4 text-gray-800">Terms and Conditions</h2>
            <div v-html="promo.instructions" class="text-sm sm:text-base text-gray-700 prose max-w-none"></div>
          </div>

          <!-- CTA Button -->
          <button @click="handleButtonClick" 
                  class="w-full bg-gradient-to-r from-red-500 to-pink-500 hover:from-red-600 hover:to-pink-600 text-white text-base sm:text-xl font-bold py-3 sm:py-4 px-4 sm:px-6 rounded-full focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-300 ease-in-out transform hover:scale-105 shadow-lg">
            Open in PayNGo App
          </button>
        </div>
      </div>

      <LoadingScreen v-if="isLoading" />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import { CalendarIcon, MapPinIcon, ClockIcon } from '@heroicons/vue/24/outline';
import LoadingScreen from '../components/LoadingScreen.vue';
import { supabase } from '../utils/supabase';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css';
import '../assets/custom-alertify.css'

export default {
  name: 'PromoDetailComponent',
  components: {
    CalendarIcon,
    MapPinIcon,
    ClockIcon,
    LoadingScreen
  },
  setup() {
    const promo = ref(null);
    const route = useRoute();
   // const router = useRouter();
    const isLoading = ref(true);

    const fetchPromo = async () => {
      const { id } = route.params;
      const { data, error } = await supabase
        .from('events')
        .select('*')
        .eq('id', id)
        .single();
      if (error) {
        alertify.error('Error fetching promo details');
        return;
      }
      promo.value = data;
      isLoading.value = false;
    };

    const handleButtonClick = () => {
      window.location.href = 'payngo://app/';
    };

    const formattedDateRange = computed(() => {
      if (promo.value) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const startDate = new Date(promo.value.start_date).toLocaleDateString(undefined, options);
        const endDate = new Date(promo.value.end_date).toLocaleDateString(undefined, options);
        return `${startDate} - ${endDate}`;
      }
      return '';
    });

    onMounted(() => {
      fetchPromo();
    });

    return {
      promo,
      handleButtonClick,
      formattedDateRange,
      isLoading
    };
  }
};
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Add any additional custom styles here */
.pattern-background {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Ccircle cx='3' cy='3' r='1.5' fill='white'/%3E%3C/svg%3E");
}
</style>
