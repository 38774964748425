<template>
  <div class="min-h-screen bg-background">
    <div v-if="isLoading" class="flex justify-center items-center min-h-screen">
      <Spinner class="h-12 w-12 text-primary" />
    </div>
    
    <div v-else-if="!event" class="flex flex-col items-center justify-center min-h-screen p-4">
      <div class="text-center">
        <h2 class="text-2xl font-bold mb-2">Event Not Found</h2>
        <p class="text-muted-foreground mb-6">The event you're looking for doesn't exist or has been removed.</p>
        <Button @click="router.push('/')">Return to Home</Button>
      </div>
    </div>
    
    <div v-else>
      <!-- Hero section with event image - Enhanced design -->
      <div class="relative h-[40vh] lg:h-[50vh]">
        <div class="absolute inset-0 bg-black/40 z-0">
          <img 
            :src="eventImage" 
            :alt="event.title" 
            class="w-full h-full object-cover"
          />
        </div>
        <!-- Add decorative pattern overlay -->
        <div class="absolute inset-0 bg-gradient-to-t from-black/70 via-black/30 to-transparent z-1"></div>
        <div class="absolute inset-0 z-1 opacity-10 mix-blend-overlay pointer-events-none">
          <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
            <defs>
              <pattern id="dots-pattern" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <circle cx="3" cy="3" r="1.5" fill="white" />
              </pattern>
            </defs>
            <rect width="100%" height="100%" fill="url(#dots-pattern)" />
          </svg>
        </div>
        
        <div class="container relative z-10 h-full flex flex-col justify-end px-4 sm:px-6 lg:px-8 pb-8">
          <Button variant="outline" class="self-start mb-6 text-white border-white/30 backdrop-blur" @click="router.push('/')">
            <ArrowLeft class="mr-2 h-4 w-4" />
            Back to Home
          </Button>
          <Badge class="mb-2 self-start">{{ event.category }}</Badge>
          <h1 class="text-3xl md:text-4xl lg:text-5xl font-bold text-white mb-2">{{ event.title }}</h1>
          <div class="flex flex-wrap gap-4 text-white/90">
            <div class="flex items-center">
              <Calendar class="h-5 w-5 mr-2" />
              <span>{{ formatDate(event.date) }}</span>
            </div>
            <div class="flex items-center">
              <MapPin class="h-5 w-5 mr-2" />
              <span>{{ event.location }}</span>
            </div>
            <div class="flex items-center">
              <Ticket class="h-5 w-5 mr-2" />
              <span>{{ event.ticketPrice }}</span>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Event details content - Enhanced design -->
      <div class="container px-4 sm:px-6 lg:px-8 py-8">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div class="lg:col-span-2">
            <Card class="relative overflow-hidden">
              <!-- Decorative element -->
              <div class="absolute top-0 right-0 h-32 w-32 bg-primary/5 rounded-full transform translate-x-16 -translate-y-16"></div>
              
              <CardHeader>
                <CardTitle>About This Event</CardTitle>
              </CardHeader>
              <CardContent>
                <p class="text-lg mb-6">{{ event.description }}</p>
                
                <h3 class="text-xl font-semibold mb-4">Schedule</h3>
                <div class="space-y-4">
                  <div v-for="(day, index) in event.schedule" :key="index" class="p-4 bg-muted/30 rounded-md border-l-4 border-primary/30">
                    <h4 class="font-medium mb-2">{{ day.day }}</h4>
                    <ul class="list-disc ml-6 space-y-1">
                      <li v-for="(activity, actIndex) in day.activities" :key="actIndex">
                        {{ activity }}
                      </li>
                    </ul>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          
          <div>
            <Card class="relative overflow-hidden">
              <!-- Decorative corner accent -->
              <div class="absolute top-0 left-0 w-16 h-16 bg-primary/10 transform -translate-x-8 -translate-y-8 rotate-45"></div>
              
              <CardHeader>
                <CardTitle>Event Information</CardTitle>
              </CardHeader>
              <CardContent>
                <div class="space-y-4">
                  <div>
                    <h4 class="text-sm font-medium text-muted-foreground mb-1">Ticket Price</h4>
                    <p class="font-medium">{{ event.ticketPrice }}</p>
                  </div>
                  
                  <div>
                    <h4 class="text-sm font-medium text-muted-foreground mb-1">Contact</h4>
                    <p class="font-medium">{{ event.contact }}</p>
                  </div>
                  
                  <div>
                    <h4 class="text-sm font-medium text-muted-foreground mb-1">Website</h4>
                    <a :href="event.website" target="_blank" class="font-medium text-primary hover:underline">
                      {{ event.website }}
                    </a>
                  </div>
                </div>
              </CardContent>
            </Card>
            
            <!-- Related Events Card - Enhanced design -->
            <Card class="mt-6 relative overflow-hidden">
              <!-- Decorative element -->
              <div class="absolute bottom-0 right-0 w-24 h-24 bg-primary/5 rounded-full transform translate-x-8 translate-y-8"></div>
              
              <CardHeader>
                <CardTitle>Other Events You Might Like</CardTitle>
              </CardHeader>
              <CardContent>
                <div class="space-y-4">
                  <div v-for="relatedEvent in relatedEvents" :key="relatedEvent.id" 
                      class="flex items-center gap-3 p-2 rounded-md hover:bg-muted/50 cursor-pointer transition-colors duration-200"
                      @click="viewEventDetails(relatedEvent.id)">
                    <div class="w-12 h-12 rounded-md overflow-hidden flex-shrink-0 bg-muted">
                      <img 
                        :src="getEventImage(relatedEvent)" 
                        :alt="relatedEvent.title" 
                        class="w-full h-full object-cover"
                        onerror="this.onerror=null; this.src='/assets/events/default-event.jpg';"
                      />
                    </div>
                    <div>
                      <p class="font-medium line-clamp-1">{{ relatedEvent.title }}</p>
                      <p class="text-sm text-muted-foreground">{{ formatDate(relatedEvent.date) }}</p>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { 
  Button, 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent,
  Badge,
  Spinner 
} from '@/components/ui';

import { 
  Calendar,
  MapPin, 
  Ticket,
  ArrowLeft
} from 'lucide-vue-next';

// Import events data
import eventsData from '@/data/events.json';

const router = useRouter();
const route = useRoute();
const isLoading = ref(true);
const event = ref(null);

// Format date helper function
function formatDate(date) {
  // Check if date is missing or invalid
  if (!date) return 'TBC';
  
  try {
    // If date contains TBC, to be confirmed, etc.
    if (typeof date === 'string' && (
        date.toLowerCase().includes('tbc') || 
        date.toLowerCase().includes('to be confirmed') || 
        date.toLowerCase().includes('tba') ||
        date.toLowerCase().includes('approx')
      )) {
      // For dates with TBC, preserve the full date string
      return date; // Return the full date string with TBC
    }
    
    // For dates with slashes (meaning approximate or range)
    if (typeof date === 'string' && date.includes('/')) {
      // Take the first date in the range
      const firstDate = date.split('/')[0].trim();
      const d = new Date(firstDate);
      
      if (isNaN(d.getTime())) {
        console.warn(`Invalid date in range: ${date}`);
        return date; // Return the original string if we can't parse it
      }
      
      return new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }).format(d) + ' (approx)';
    }
    
    // Try parsing the date normally
    const d = new Date(date);
    
    // Check if date is valid
    if (isNaN(d.getTime())) {
      console.warn(`Invalid date encountered: ${date}`);
      return date; // Return the original string if we can't parse it
    }
    
    // Format the date
    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }).format(d);
  } catch (error) {
    console.error(`Error formatting date: ${date}`, error);
    return date; // Return the original string on error
  }
}

// Navigate to another event
function viewEventDetails(eventId) {
  router.push({ name: 'event-details', params: { id: eventId }});
}

// Get related events (other events with same category or events from closest dates)
const relatedEvents = computed(() => {
  if (!event.value) return [];
  
  return eventsData
    .filter(e => e.id !== event.value.id)
    .sort((a, b) => {
      // Prioritize same category events
      if (a.category === event.value.category && b.category !== event.value.category) return -1;
      if (a.category !== event.value.category && b.category === event.value.category) return 1;
      
      // Then sort by date
      return new Date(a.date) - new Date(b.date);
    })
    .slice(0, 3); // Show only 3 related events
});

const eventImage = computed(() => {
  if (!event.value) return '/assets/events/default-event.jpg';
  return event.value.image || event.value.fallbackImage || '/assets/events/default-event.jpg';
});

function getEventImage(event) {
  if (!event || !event.image) {
    // Return default image if event is missing or has no image
    return '/assets/events/default-event.jpg';
  }
  return event.image;
}

onMounted(() => {
  const eventId = parseInt(route.params.id);
  
  setTimeout(() => {
    // Find event by ID
    event.value = eventsData.find(e => e.id === eventId) || null;
    if (event.value) {
      console.log('Event image path:', event.value.image);
    }
    isLoading.value = false;
  }, 500);
});
</script> 