import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/main.css'; // Import Tailwind CSS
import './assets/tailwind.css'; // Import Tailwind CSS
import { inject } from '@vercel/analytics';

// Initialize Vercel Analytics
inject();

// Create and mount the Vue app
const app = createApp(App);
app.use(router);
app.mount('#app');
