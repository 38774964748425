<template>
  <div>
    <!-- Button to open the dialog -->
    <button 
      @click="isDialogOpen = true"
      class="bg-white text-blue-600 hover:bg-gray-100 px-4 py-2 rounded-md flex items-center"
    >
      Create Your Itinerary
      <svg 
        xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        viewBox="0 0 24 24" 
        fill="none" 
        stroke="currentColor" 
        stroke-width="2" 
        stroke-linecap="round" 
        stroke-linejoin="round" 
        class="ml-2 h-4 w-4"
      >
        <path d="M5 12h14"></path>
        <path d="m12 5 7 7-7 7"></path>
      </svg>
    </button>

    <!-- Dialog component -->
    <Dialog 
      :isOpen="isDialogOpen" 
      :title="dialogTitle"
      @close="handleDialogClose"
    >
      <!-- Form view -->
      <div v-if="currentView === 'form'">
        <p class="text-gray-600 mb-6">
          Tell us about your trip and we'll help you plan the perfect Sarawak experience.
        </p>

        <form @submit.prevent="handleSubmit" class="space-y-4">
          <div class="space-y-2">
            <label for="name" class="block text-sm font-medium">Your Name</label>
            <input
              id="name"
              v-model="formData.name"
              class="w-full p-2 border rounded-md"
              required
            />
          </div>
          
          <div class="space-y-2">
            <label for="email" class="block text-sm font-medium">Email Address</label>
            <input
              id="email"
              type="email"
              v-model="formData.email"
              class="w-full p-2 border rounded-md"
              required
            />
          </div>
          
          <div class="space-y-2">
            <label for="dates" class="block text-sm font-medium">When are you planning to visit?</label>
            <input
              id="dates"
              v-model="formData.dates"
              placeholder="e.g., April 15-20, 2025"
              class="w-full p-2 border rounded-md"
            />
          </div>
          
          <div class="space-y-2">
            <label for="interests" class="block text-sm font-medium">What are you interested in?</label>
            <input
              id="interests"
              v-model="formData.interests"
              placeholder="e.g., Nature, Culture, Food"
              class="w-full p-2 border rounded-md"
            />
          </div>
          
          <div class="space-y-2">
            <label for="additionalInfo" class="block text-sm font-medium">Additional Information</label>
            <textarea
              id="additionalInfo"
              v-model="formData.additionalInfo"
              placeholder="Tell us more about your preferences..."
              rows="4"
              class="w-full p-2 border rounded-md"
            ></textarea>
          </div>
          
          <div class="pt-4 flex justify-between">
            <button 
              type="button" 
              @click="isDialogOpen = false"
              class="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button 
              type="submit"
              class="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 flex items-center"
              :disabled="isLoading"
            >
              <svg v-if="isLoading" class="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              {{ isLoading ? 'Generating...' : 'Submit Request' }}
            </button>
          </div>
        </form>
      </div>

      <!-- Loading view -->
      <div v-else-if="currentView === 'loading'" class="py-12 text-center">
        <div class="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-600 mx-auto"></div>
        <p class="mt-6 text-lg font-medium">Creating your itinerary...</p>
        <p class="mt-2 text-gray-500">This may take a moment as our AI crafts your perfect trip.</p>
      </div>

      <!-- Result view -->
      <div v-else-if="currentView === 'result'" class="space-y-4">
        <p class="text-green-600 font-medium">Your personalized itinerary is ready!</p>
        
        <div class="bg-gray-50 p-4 rounded-lg border border-gray-200 max-h-[60vh] overflow-auto">
          <div class="prose max-w-none" v-html="aiItinerary"></div>
        </div>
        
        <div class="pt-4 flex justify-between">
          <button 
            type="button" 
            @click="goBackToForm"
            class="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
          >
            Modify Preferences
          </button>
          <button 
            @click="handlePrintItinerary" 
            class="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Print Itinerary
          </button>
        </div>
      </div>

      <!-- Error view -->
      <div v-else-if="currentView === 'error'" class="py-8 text-center">
        <div class="text-red-500 mb-4">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        <h3 class="text-lg font-medium">We encountered an error</h3>
        <p class="mt-2 text-gray-600 mb-6">{{ errorMessage }}</p>
        <button 
          @click="currentView = 'form'" 
          class="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Try Again
        </button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from './ui/Dialog.vue';
import { generateItinerary } from '@/api/generateItinerary';

export default {
  name: 'SimpleItineraryPlanner',
  components: {
    Dialog
  },
  data() {
    return {
      isDialogOpen: false,
      currentView: 'form', // form, loading, result, error
      dialogTitle: 'Create Your Personalized Itinerary',
      isLoading: false,
      errorMessage: '',
      formData: {
        name: '',
        email: '',
        dates: '',
        interests: '',
        additionalInfo: ''
      },
      aiItinerary: ''
    }
  },
  created() {
    // Check API key availability
    if (!process.env.VUE_APP_GOOGLE_AI_API_KEY) {
      console.warn('API key not found in process.env');
    }
  },
  watch: {
    currentView(newValue) {
      // Update dialog title based on current view
      if (newValue === 'result') {
        this.dialogTitle = 'Your Sarawak Itinerary';
      } else {
        this.dialogTitle = 'Create Your Personalized Itinerary';
      }
    }
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true;
      
      try {
        this.currentView = 'loading';
        const prompt = this.generatePrompt();
        const response = await generateItinerary(prompt);
        this.aiItinerary = response.itinerary;
        this.currentView = 'result';
      } catch (error) {
        console.error('Error generating itinerary:', error);
        this.errorMessage = 'We couldn\'t generate your itinerary at this time. Please check the API configuration.';
        this.currentView = 'error';
      } finally {
        this.isLoading = false;
      }
    },
    
    generatePrompt() {
      return `
Create a detailed travel itinerary for ${this.formData.name} visiting Sarawak, Malaysia with the following preferences:

- Travel dates: ${this.formData.dates || 'Flexible'}
- Interests: ${this.formData.interests || 'General tourism'}
- Additional information: ${this.formData.additionalInfo || 'No specific requirements'}

Please provide a day-by-day itinerary that includes:
1. Recommended attractions and activities
2. Where to eat (breakfast, lunch, dinner)
3. Accommodation suggestions
4. Transportation tips
5. Estimated costs and budget considerations
6. Local cultural insights and etiquette tips

Format the itinerary in a clear, well-structured way with days, times, and activities. Include some brief descriptions of the main attractions.
`;
    },
    
    goBackToForm() {
      this.currentView = 'form';
    },
    
    handleDialogClose() {
      // Reset everything when dialog is closed
      this.isDialogOpen = false;
      setTimeout(() => {
        this.currentView = 'form';
        this.aiItinerary = '';
        this.errorMessage = '';
      }, 300);
    },
    
    async handlePrintItinerary() {
      try {
        const printWindow = window.open('', '_blank');
        
        // Show loading state in print window
        printWindow.document.write(`
          <div style="display: flex; justify-content: center; align-items: center; height: 100vh;">
            <p style="font-family: Arial; color: #666;">Preparing your itinerary for printing...</p>
          </div>
        `);

        // Get the PDF-formatted content
        const response = await generateItinerary(this.generatePrompt(), 'pdf');
        
        // Write the formatted content
        printWindow.document.open();
        printWindow.document.write(response.itinerary);
        printWindow.document.close();
      } catch (error) {
        console.error('Error preparing print version:', error);
        this.errorMessage = 'Failed to prepare the print version. Please try again.';
        this.currentView = 'error';
      }
    }
  }
}
</script>

<style scoped>
/* Add any additional styling here if needed */
</style> 