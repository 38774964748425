<template>
  <div
    class="rounded-lg border bg-card text-card-foreground shadow-sm"
    :class="className"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UiCard'
}
</script>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  className: {
    type: String,
    default: ''
  }
});
</script> 