<template>
  <div
    :class="[
      'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
      variant === 'default' ? 'border-transparent bg-primary text-primary-foreground hover:bg-primary/80' : '',
      variant === 'secondary' ? 'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80' : '',
      variant === 'destructive' ? 'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80' : '',
      variant === 'outline' ? 'text-foreground' : '',
      className
    ]"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'UiBadge'
}
</script>

<script setup>
// eslint-disable-next-line no-undef
defineOptions({
  name: 'UiBadge'
});

import { defineProps } from 'vue';

const props = defineProps({
  variant: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'secondary', 'destructive', 'outline'].includes(value)
  },
  className: {
    type: String,
    default: ''
  }
});
</script> 