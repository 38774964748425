<template>
  <Teleport to="body">
    <div v-if="isOpen" class="fixed inset-0 z-50 flex items-center justify-center">
      <!-- Backdrop/overlay -->
      <div 
        class="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity" 
        @click="close"
      ></div>
      
      <!-- Dialog content -->
      <div 
        class="bg-white rounded-lg shadow-xl max-w-md w-full mx-4 max-h-[90vh] overflow-auto z-10 relative"
        role="dialog"
        aria-modal="true"
      >
        <div class="p-6">
          <div class="flex justify-between items-start mb-4">
            <h2 class="text-xl font-bold">{{ title }}</h2>
            <button 
              @click="close" 
              class="text-gray-500 hover:text-gray-700 focus:outline-none"
              aria-label="Close dialog"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </div>
          
          <div class="mt-2">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  emits: ['close'],
  methods: {
    close() {
      this.$emit('close');
    }
  },
  mounted() {
    // Prevent body scrolling when dialog is open
    if (this.isOpen) {
      document.body.style.overflow = 'hidden';
    }
  },
  updated() {
    // Toggle body scrolling when dialog open state changes
    document.body.style.overflow = this.isOpen ? 'hidden' : '';
  },
  beforeUnmount() {
    // Ensure we restore scrolling when component is unmounted
    document.body.style.overflow = '';
  }
}
</script> 