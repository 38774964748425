import { createRouter, createWebHistory } from 'vue-router';
import SignInComponent from '../components/SignInComponent.vue';
import SignUpComponent from '../components/SignUpComponent.vue';
import EventDetailComponent from '../components/EventDetailComponent.vue';
import MerchandiseComponent from '../components/MerchandiseComponent.vue';
import BookingComponent from '../components/BookingComponent.vue';
import ProfileComponent from '../components/ProfileComponent.vue';
import BillingComponent from '../components/BillingComponent.vue';
import MyPurchasesComponent from '../components/MyPurchasesComponent.vue';
import MyTicketsComponent from '../components/MyTicketsComponent.vue';
import RegistrationConfirmationComponent from '../components/RegistrationConfirmationComponent.vue';
import RegisteredEventsComponent from '../components/RegisteredEventsComponent.vue'; // Import the new component
import ResetPasswordComponent from '../components/ResetPasswordComponent.vue';
import ForgotPasswordComponent from '../components/ForgotPasswordComponent.vue';
import CartConfirmationComponent from '../components/CartConfirmationComponent.vue';
import HelpRequestComponent from '../components/HelpRequestComponent.vue';
import PromoDetailComponent  from '../components/PromoDetailComponent.vue';
import PaymentStatus from '../components/PaymentStatus.vue';
import LandingPage from '@/views/LandingPage.vue';
import EventDetailPage from '@/views/EventDetailPage.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: LandingPage
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: SignInComponent
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUpComponent
  },
  {
    path: '/event/:id',
    name: 'EventDetail',
    component: EventDetailComponent
  },
  {
    path: '/merchandise/:id',
    name: 'Merchandise',
    component: MerchandiseComponent
  },
  {
    path: '/booking/:id',
    name: 'Booking',
    component: BookingComponent
  },
  {
    path: '/profile',
    name: 'Profile',
    component: ProfileComponent
  },
  {
    path: '/billing',
    name: 'Billing',
    component: BillingComponent
  }, {
    path: '/my-tickets',
    name: 'MyTickets',
    component: MyTicketsComponent
  },
  {
    path: '/my-purchases',
    name: 'MyPurchases',
    component: MyPurchasesComponent,
  },
  {
    path: '/registration-confirmation/:id',
    name: 'RegistrationConfirmation',
    component: RegistrationConfirmationComponent
  },{
    path: '/registered-events', // Add the new route
    name: 'RegisteredEvents',
    component: RegisteredEventsComponent
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPasswordComponent,
  },{
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPasswordComponent,
  },
  {
    path: '/cart-confirmation',
    name: 'CartConfirmation',
    component: CartConfirmationComponent
  },
  {
    path: '/get-help',
    name: 'HelpRequest',
    component: HelpRequestComponent
  },
  {
    path: '/promo/:id',
    name: 'PromoDetail',
    component: PromoDetailComponent 
  },
  {
    path: '/payment_status',
    name: 'PaymentStatus',
    component: PaymentStatus
  },
  {
    path: '/events/:id',
    name: 'event-details',
    component: EventDetailPage,
    props: true
  },
  {
    path: '/attractions/:id',
    name: 'attraction-details',
    component: () => import('@/views/AttractionDetailPage.vue'),
    props: true
  },
  {
    path: '/holidays/:id',
    name: 'holiday-details',
    component: () => import('@/views/HolidayDetailPage.vue'),
    props: true
  },
  {
    path: '/events',
    name: 'events-list',
    component: () => import('@/views/EventsListPage.vue')
  },
  {
    path: '/holidays',
    name: 'holidays-list',
    component: () => import('@/views/HolidaysListPage.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // Always scroll to top when navigating
    return { top: 0 }
  }
});

export default router;
