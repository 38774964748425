// Import axios or your preferred HTTP client
import axios from 'axios';

/**
 * Calls the Google AI Gemini API to generate an itinerary
 * @param {string} prompt - The prompt for generating the itinerary
 * @param {string} format - The format of the itinerary (html or pdf)
 * @returns {Promise<string>} - The generated itinerary HTML or PDF content
 */
export async function generateItinerary(prompt, format = 'html') {
  try {
    // Get API key from environment
    const apiKey = process.env.VUE_APP_GOOGLE_AI_API_KEY || window.VUE_APP_GOOGLE_AI_API_KEY;
    
    if (!apiKey) {
      console.error('API Key not found in environment:', process.env);
      throw new Error('Google AI API key not found. Please check your environment configuration.');
    }

    const apiUrl = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-2.0-flash:generateContent';
    
    console.log('Using API Key:', apiKey); // For debugging
    
    const response = await axios.post(
      `${apiUrl}?key=${apiKey}`,
      {
        contents: [{
          parts: [{
            text: prompt
          }]
        }]
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    
    // Extract and format the response
    const generatedText = response.data.candidates[0].content.parts[0].text;
    
    // Return appropriate format
    return {
      itinerary: format === 'pdf' ? generatePdfContent(generatedText) : formatItineraryAsHtml(generatedText)
    };
  } catch (error) {
    console.error('Error in generateItinerary:', error);
    if (error.response) {
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
    }
    throw new Error(error.message || 'Failed to generate itinerary');
  }
}

/**
 * Formats the plain text itinerary from AI into HTML
 */
function formatItineraryAsHtml(text) {
  return `
    <div class="itinerary-container max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
      <div class="itinerary-content space-y-6">
        ${text
          // Format main headers
          .replace(/^# (.*$)/gm, '<h1 class="text-3xl font-bold text-blue-600 mb-6">$1</h1>')
          .replace(/^## (.*$)/gm, '<h2 class="text-2xl font-semibold text-gray-800 mb-4">$2</h2>')
          
          // Format day headers with special styling
          .replace(
            /^Day (\d+):(.*$)/gm, 
            '<div class="day-section bg-gray-50 p-6 rounded-lg mb-8">' +
            '<h2 class="text-xl font-bold text-blue-600 mb-4">Day $1:$2</h2>'
          )
          
          // Format time headers
          .replace(/^Time: (.*$)/gm, '<h4 class="text-lg font-semibold text-gray-700 mt-4">Time: $1</h4>')
          
          // Format activity headers
          .replace(/^Activity: (.*$)/gm, '<h4 class="text-lg font-semibold text-blue-500 mt-2">Activity: $1</h4>')
          
          // Format accommodation and transportation
          .replace(/^(Accommodation|Transportation): (.*$)/gm, 
            '<div class="info-block bg-blue-50 p-4 rounded my-3">' +
            '<span class="font-semibold text-blue-600">$1:</span> ' +
            '<span class="text-gray-700">$2</span>' +
            '</div>'
          )
          
          // Format lists
          .replace(/^\* (.*$)/gm, '<li class="ml-4 mb-2">$1</li>')
          .replace(/^- (.*$)/gm, '<li class="ml-4 mb-2">$1</li>')
          
          // Wrap lists
          .replace(/(<li.*<\/li>)\n(?![<]li)/g, '$1</ul>\n')
          .replace(/(?<!\n<ul>)\n(<li>)/g, '\n<ul class="list-disc space-y-2 my-4">$1')
          
          // Format paragraphs
          .replace(/\n\n/g, '</p><p class="text-gray-600 leading-relaxed mb-4">')
          
          // Format bold and italic
          .replace(/\*\*(.*?)\*\*/g, '<strong class="text-gray-800">$1</strong>')
          .replace(/\*(.*?)\*/g, '<em class="text-gray-700">$1</em>')
          
          // Wrap remaining text
          .replace(/^(?!<[h|d|u|p])(.+)$/gm, '<p class="text-gray-600 leading-relaxed mb-4">$1</p>')}
      </div>
    </div>
  `;
}

// Add a new function for PDF generation
function generatePdfContent(text) {
  return `
    <!DOCTYPE html>
    <html>
    <head>
      <meta charset="UTF-8">
      <title>Sarawak Travel Itinerary</title>
      <style>
        @page {
          margin: 2cm;
        }
        body {
          font-family: Arial, sans-serif;
          line-height: 1.6;
          color: #333;
        }
        .itinerary-container {
          max-width: 800px;
          margin: 0 auto;
        }
        .day-section {
          background: #f8f9fa;
          padding: 20px;
          margin: 20px 0;
          border-radius: 8px;
        }
        h1 {
          color: #2563eb;
          font-size: 24px;
          margin-bottom: 20px;
        }
        h2 {
          color: #1e40af;
          font-size: 20px;
          margin: 16px 0;
        }
        .info-block {
          background: #eff6ff;
          padding: 12px;
          margin: 12px 0;
          border-radius: 4px;
        }
        ul {
          margin-left: 20px;
          margin-bottom: 16px;
        }
        li {
          margin-bottom: 8px;
        }
        .header {
          text-align: center;
          margin-bottom: 40px;
        }
        .footer {
          text-align: center;
          margin-top: 40px;
          font-size: 12px;
          color: #666;
        }
      </style>
    </head>
    <body>
      ${formatItineraryAsHtml(text)}
    </body>
    </html>
  `;
} 