<template>
  <div class="min-h-screen bg-background">
    <!-- Hero Section with Image Slider -->
    <section class="relative h-[60vh] flex items-center">
      <!-- Image Slider Container -->
      <div class="absolute inset-0 bg-black/50 z-0 overflow-hidden">
        <!-- First slide -->
        <div 
          class="absolute inset-0 transition-opacity duration-500 ease-in-out"
          :class="{ 'opacity-0': currentSlide !== 0, 'opacity-100': currentSlide === 0 }"
        >
          <img 
            src="/assets/sarawak-hero.jpg" 
            alt="Sarawak" 
            class="w-full h-full object-cover mix-blend-overlay"
          />
        </div>
        
        <!-- Second slide -->
        <div 
          class="absolute inset-0 transition-opacity duration-500 ease-in-out"
          :class="{ 'opacity-0': currentSlide !== 1, 'opacity-100': currentSlide === 1 }"
        >
          <img 
            src="/assets/sarawak-hero2.jpg" 
            alt="Sarawak" 
            class="w-full h-full object-cover mix-blend-overlay"
          />
        </div>
      </div>
      
      <!-- Content -->
      <div class="container relative z-10 px-4 sm:px-6 lg:px-8">
        <div class="max-w-3xl text-white">
          <h1 class="text-4xl font-bold tracking-tight sm:text-5xl md:text-6xl">
            Discover Sarawak
          </h1>
          <p class="mt-6 text-xl">
            Explore upcoming events, local gems, and holidays in Sarawak's vibrant landscape.
          </p>
          <div class="mt-10 flex flex-col sm:flex-row gap-4">
            <Button class="bg-primary hover:bg-primary/90">Explore Events</Button>
            <Button variant="outline" class="bg-background/20 hover:bg-background/30 backdrop-blur">
              Discover Local Gems
            </Button>
          </div>
        </div>
      </div>
      
      <!-- Slider navigation dots -->
      <div class="absolute bottom-4 left-0 right-0 z-10 flex justify-center space-x-2">
        <button 
          @click="handleSlideClick(0)" 
          class="w-3 h-3 rounded-full transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-white/50 focus:ring-offset-2"
          :class="currentSlide === 0 ? 'bg-white' : 'bg-white/50 hover:bg-white/80'"
          aria-label="Show first slide"
        ></button>
        <button 
          @click="handleSlideClick(1)" 
          class="w-3 h-3 rounded-full transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-white/50 focus:ring-offset-2"
          :class="currentSlide === 1 ? 'bg-white' : 'bg-white/50 hover:bg-white/80'"
          aria-label="Show second slide"
        ></button>
      </div>
      
      <!-- Arrow navigation -->
      <button 
        @click="handlePrevClick()" 
        class="absolute left-4 top-1/2 -translate-y-1/2 z-10 p-2 bg-black/30 text-white rounded-full hover:bg-black/50 focus:outline-none focus:ring-2 focus:ring-white/50 backdrop-blur-sm"
        aria-label="Previous slide"
      >
        <ChevronLeft class="h-6 w-6" />
      </button>
      
      <button 
        @click="handleNextClick()" 
        class="absolute right-4 top-1/2 -translate-y-1/2 z-10 p-2 bg-black/30 text-white rounded-full hover:bg-black/50 focus:outline-none focus:ring-2 focus:ring-white/50 backdrop-blur-sm"
        aria-label="Next slide"
      >
        <ChevronRight class="h-6 w-6" />
      </button>
    </section>

    <!-- Search Section (Removed Filters) -->
    <section class="py-8 border-b bg-card">
      <div class="container px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-center">
          <div class="relative w-full max-w-2xl">
            <Input 
              type="text" 
              placeholder="Search events, places..." 
              class="pr-10"
              v-model="searchTerm"
            />
            <Search class="h-5 w-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-muted-foreground" />
          </div>
        </div>
      </div>
    </section>

    <!-- Upcoming Events Section -->
    <section class="py-12 bg-background">
      <div class="container px-4 sm:px-6 lg:px-8 mx-auto">
        <div class="flex items-center justify-between mb-8">
          <h2 class="text-3xl font-bold tracking-tight">Upcoming Events</h2>
          <Button variant="ghost" class="text-primary" @click="router.push({ name: 'events-list' })">
            View All 
            <ChevronRight class="ml-2 h-4 w-4" />
          </Button>
        </div>
        
        <div v-if="isLoading" class="flex justify-center my-12">
          <Spinner class="h-12 w-12 text-primary" />
        </div>
        
        <div v-else-if="nearestEvents.length === 0" class="text-center py-12">
          <Calendar class="h-12 w-12 mx-auto text-muted-foreground" />
          <h3 class="mt-4 text-lg font-medium">No events found</h3>
          <p class="mt-2 text-sm text-muted-foreground">
            Try adjusting your search to find what you're looking for.
          </p>
        </div>
        
        <div v-else class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 justify-items-center max-w-7xl mx-auto">
          <Card v-for="event in nearestEvents" :key="event.id" class="overflow-hidden w-full max-w-md">
            <div class="relative h-48">
              <img 
                :src="event.image" 
                :alt="event.title" 
                class="w-full h-full object-cover"
              />
              <div class="absolute top-4 left-4 bg-primary text-white px-3 py-1 rounded-full text-sm font-medium">
                {{ formatDate(event.date) }}
              </div>
            </div>
            <CardContent class="p-6">
              <h3 class="text-xl font-semibold line-clamp-1">{{ event.title }}</h3>
              <div class="flex items-center mt-2 text-sm text-muted-foreground">
                <MapPin class="h-4 w-4 mr-1 flex-shrink-0" />
                <span class="truncate">{{ event.location }}</span>
              </div>
              <p class="mt-3 text-sm line-clamp-2">{{ event.details }}</p>
            </CardContent>
            <CardFooter class="p-6 pt-0 flex justify-between">
              <Badge>{{ event.category }}</Badge>
              <Button variant="ghost" size="sm" class="text-primary" @click="viewEventDetails(event.id)">
                View Details
              </Button>
            </CardFooter>
          </Card>
        </div>
      </div>
    </section>

    <!-- Local Gems Section -->
    <section class="py-12 bg-muted/30">
      <div class="container px-4 sm:px-6 lg:px-8 mx-auto">
        <div class="flex items-center justify-between mb-8">
          <h2 class="text-3xl font-bold tracking-tight">Local Gems</h2>
          <Button variant="ghost" class="text-primary">
            Explore More 
            <ChevronRight class="ml-2 h-4 w-4" />
          </Button>
        </div>
        
        <div v-if="isLoading" class="flex justify-center my-12">
          <Spinner class="h-12 w-12 text-primary" />
        </div>
        
        <div v-else-if="filteredAttractions.length === 0" class="text-center py-12">
          <MapPin class="h-12 w-12 mx-auto text-muted-foreground" />
          <h3 class="mt-4 text-lg font-medium">No attractions found</h3>
          <p class="mt-2 text-sm text-muted-foreground">
            Try adjusting your search to discover local gems.
          </p>
        </div>
        
        <div v-else class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 justify-items-center max-w-7xl mx-auto">
          <Card 
            v-for="attraction in filteredAttractions" 
            :key="attraction.id" 
            class="group overflow-hidden cursor-pointer w-full max-w-sm"
            @click="viewAttractionDetails(attraction.id)"
          >
            <div class="relative h-56 overflow-hidden">
              <img 
                :src="attraction.image" 
                :alt="attraction.name" 
                class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
              />
              <div class="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
              <div class="absolute bottom-4 left-4 right-4">
                <h3 class="text-lg font-semibold text-white">{{ attraction.name }}</h3>
                <div class="flex items-center mt-1 text-sm text-white/80">
                  <MapPin />
                  <span class="truncate">{{ attraction.location }}</span>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </section>

    <!-- Upcoming Holidays Section -->
    <section class="py-12 bg-background">
      <div class="container px-4 sm:px-6 lg:px-8 mx-auto">
        <div class="flex items-center justify-between mb-8">
          <h2 class="text-3xl font-bold tracking-tight">Upcoming Holidays</h2>
          <Button variant="ghost" class="text-primary" @click="router.push({ name: 'holidays-list' })">
            View All 
            <ChevronRight class="ml-2 h-4 w-4" />
          </Button>
        </div>
        
        <div v-if="isLoading" class="flex justify-center my-12">
          <Spinner class="h-12 w-12 text-primary" />
        </div>
        
        <div v-else-if="topHolidays.length === 0" class="text-center py-12">
          <Calendar class="h-12 w-12 mx-auto text-muted-foreground" />
          <h3 class="mt-4 text-lg font-medium">No holidays found</h3>
          <p class="mt-2 text-sm text-muted-foreground">
            Try adjusting your search to find holidays.
          </p>
        </div>
        
        <div v-else class="grid grid-cols-1 md:grid-cols-2 gap-6 justify-items-center max-w-6xl mx-auto">
          <Card v-for="holiday in topHolidays" :key="holiday.id" 
                class="cursor-pointer hover:border-primary/50 w-full max-w-lg"
                @click="viewHolidayDetails(holiday.id)">
            <CardHeader>
              <CardTitle class="flex items-center">
                <Calendar class="h-5 w-5 mr-2 text-primary" />
                {{ holiday.name }}
              </CardTitle>
              <CardDescription>
                {{ formatDate(holiday.date) }}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <p class="text-sm">{{ holiday.description }}</p>
            </CardContent>
          </Card>
        </div>
      </div>
    </section>
   

    <!-- Event Planning CTA -->
    <section class="bg-primary text-primary-foreground py-16">
      <div class="container px-4 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto text-center">
          <h2 class="text-3xl font-bold">Planning to Visit Sarawak?</h2>
          <p class="mt-4 text-lg text-primary-foreground/90">
            Let us help you find the perfect events and attractions for your trip.
          </p>
          <div class="mt-8">
            <SimpleItineraryPlanner class="inline-block" />
          </div>
        </div>
      </div>
    </section>

    <!-- Footer Menu -->
    <footer class="bg-background border-t">
      <div class="container px-4 sm:px-6 lg:px-8 mx-auto py-12">
        <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
          <!-- About Section -->
          <div>
            <h3 class="text-lg font-semibold mb-4">About Sarawak Events</h3>
            <p class="text-sm text-muted-foreground">
              Your comprehensive guide to events, festivals, and cultural celebrations across Sarawak. Discover the heart of Borneo's rich heritage and vibrant community.
            </p>
          </div>

          <!-- Quick Links -->
          <div>
            <h3 class="text-lg font-semibold mb-4">Quick Links</h3>
            <ul class="space-y-2">
              <li>
                <Button 
                  variant="link" 
                  class="text-muted-foreground hover:text-primary p-0 h-auto"
                  @click="router.push({ name: 'events-list' })"
                >
                  All Events
                </Button>
              </li>
              <li>
                <Button 
                  variant="link" 
                  class="text-muted-foreground hover:text-primary p-0 h-auto"
                  @click="router.push({ name: 'holidays-list' })"
                >
                  Holidays
                </Button>
              </li>
              <li>
                <Button 
                  variant="link" 
                  class="text-muted-foreground hover:text-primary p-0 h-auto"
                >
                  Local Gems
                </Button>
              </li>
            </ul>
          </div>

          <!-- Legal -->
          <div>
            <h3 class="text-lg font-semibold mb-4">Legal</h3>
            <ul class="space-y-2">
              <li>
                <Button 
                  variant="link" 
                  class="text-muted-foreground hover:text-primary p-0 h-auto"
                  @click="router.push({ name: 'terms' })"
                >
                  Terms & Conditions
                </Button>
              </li>
              <li>
                <Button 
                  variant="link" 
                  class="text-muted-foreground hover:text-primary p-0 h-auto"
                  @click="router.push({ name: 'privacy' })"
                >
                  Privacy Policy
                </Button>
              </li>
              <li>
                <Button 
                  variant="link" 
                  class="text-muted-foreground hover:text-primary p-0 h-auto"
                  @click="router.push({ name: 'cookies' })"
                >
                  Cookie Policy
                </Button>
              </li>
            </ul>
          </div>

          <!-- Contact -->
          <div>
            <h3 class="text-lg font-semibold mb-4">Contact Us</h3>
            <ul class="space-y-3">
              <li class="flex items-center text-sm text-muted-foreground">
                <Mail class="h-4 w-4 mr-2" />
                info@sarawakevents.com
              </li>
              <li class="flex items-center text-sm text-muted-foreground">
                <Phone class="h-4 w-4 mr-2" />
                +60 82-123456
              </li>
              <li class="flex items-center text-sm text-muted-foreground">
                <MapPin class="h-4 w-4 mr-2" />
                Kuching, Sarawak, Malaysia
              </li>
            </ul>
            
            <!-- Social Media Links -->
            <div class="flex space-x-4 mt-6">
              <a href="#" class="text-muted-foreground hover:text-primary">
                <Facebook class="h-5 w-5" />
              </a>
              <a href="#" class="text-muted-foreground hover:text-primary">
                <Instagram class="h-5 w-5" />
              </a>
              <a href="#" class="text-muted-foreground hover:text-primary">
                <Twitter class="h-5 w-5" />
              </a>
            </div>
          </div>
        </div>

        <!-- Copyright -->
        <div class="border-t mt-12 pt-8 text-center text-sm text-muted-foreground">
          <p>&copy; {{ new Date().getFullYear() }} Sarawak Events. All rights reserved.</p>
        </div>
      </div>
    </footer>

  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { 
  Button, 
  Input, 
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
  Badge,
  Spinner
} from '@/components/ui';

// Import icons directly without alias to match template usage
import { 
  Search, 
  Calendar, 
  MapPin, 
  ChevronRight,
  ChevronLeft,
  Mail,
  Phone,
  Facebook,
  Instagram,
  Twitter
} from 'lucide-vue-next';

// Import JSON data
import eventsData from '@/data/events.json';
import attractionsData from '@/data/attractions.json';
import holidaysData from '@/data/holidays.json';

// Add this import for the itinerary planner component
import SimpleItineraryPlanner from '@/components/SimpleItineraryPlanner.vue';

const router = useRouter();
const isLoading = ref(false);
const upcomingEvents = ref([]);
const localAttractions = ref([]);
const upcomingHolidays = ref([]);

// Search state (removed filter state variables)
const searchTerm = ref('');

// Slider state
const currentSlide = ref(0);
const totalSlides = 2; // Number of slides
const autoSlideInterval = ref(null);

// Enhanced formatDate function to handle more date formats
function formatDate(date) {
  // Check if date is missing or invalid
  if (!date) return 'TBC';
  
  try {
    // If date contains TBC, to be confirmed, etc.
    if (typeof date === 'string' && (
        date.toLowerCase().includes('tbc') || 
        date.toLowerCase().includes('to be confirmed') || 
        date.toLowerCase().includes('tba') ||
        date.toLowerCase().includes('approx')
      )) {
      return date; // Always return the full date string with TBC
    }
    
    // For dates with slashes (meaning approximate or range)
    if (typeof date === 'string' && date.includes('/')) {
      // Take the first date in the range
      const firstDate = date.split('/')[0].trim();
      const d = new Date(firstDate);
      
      if (isNaN(d.getTime())) {
        console.warn(`Invalid date in range: ${date}`);
        return date; // Return the original string if we can't parse it
      }
      
      return new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }).format(d) + ' (approx)';
    }
    
    // Try parsing the date normally
    const d = new Date(date);
    
    // Check if date is valid
    if (isNaN(d.getTime())) {
      console.warn(`Invalid date encountered: ${date}`);
      return date; // Return the original string if we can't parse it
    }
    
    // Format the date
    return new Intl.DateTimeFormat('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    }).format(d);
  } catch (error) {
    console.error(`Error formatting date: ${date}`, error);
    return date; // Return the original string on error
  }
}

// Simplified filtered events (removed category and date filtering)
const filteredEvents = computed(() => {
  if (!upcomingEvents.value.length) return [];
  
  let result = [...upcomingEvents.value];
  
  // Apply search filter
  if (searchTerm.value) {
    const search = searchTerm.value.toLowerCase();
    result = result.filter(event => 
      event.title.toLowerCase().includes(search) ||
      event.location.toLowerCase().includes(search) ||
      event.details.toLowerCase().includes(search) ||
      event.category.toLowerCase().includes(search)
    );
  }
  
  return result;
});

// Simplified filtered attractions (only search)
const filteredAttractions = computed(() => {
  if (!localAttractions.value.length) return [];
  
  let result = [...localAttractions.value];
  
  // Apply search filter
  if (searchTerm.value) {
    const search = searchTerm.value.toLowerCase();
    result = result.filter(attraction => 
      attraction.name.toLowerCase().includes(search) ||
      attraction.location.toLowerCase().includes(search) ||
      attraction.description.toLowerCase().includes(search)
    );
  }
  
  return result;
});

// Simplified filtered holidays (only search)
const filteredHolidays = computed(() => {
  if (!upcomingHolidays.value.length) return [];
  
  let result = [...upcomingHolidays.value];
  
  // Apply search filter
  if (searchTerm.value) {
    const search = searchTerm.value.toLowerCase();
    result = result.filter(holiday => 
      holiday.name.toLowerCase().includes(search) ||
      holiday.description.toLowerCase().includes(search)
    );
  }
  
  return result;
});

// Update the nearestEvents computed property
const nearestEvents = computed(() => {
  // First apply any search filtering
  const filtered = filteredEvents.value;
  
  // Today's date for comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to start of day for fair comparison
  
  // Sort by date (nearest first)
  return filtered
    .sort((a, b) => {
      // Function to extract a date we can compare
      function getComparableDate(eventDate) {
        // Default to far future for TBC or invalid dates
        let result = new Date('9999-12-31');
        
        if (!eventDate) return result;
        
        // If date has TBC or is approximate, try to extract the date part
        if (typeof eventDate === 'string') {
          if (eventDate.toLowerCase().includes('tbc') || 
              eventDate.toLowerCase().includes('to be confirmed') || 
              eventDate.toLowerCase().includes('tba')) {
            
            // First, try to extract ISO date format (YYYY-MM-DD)
            const isoMatches = eventDate.match(/(\d{4}-\d{2}-\d{2})/);
            if (isoMatches && isoMatches.length >= 2) {
              const d = new Date(isoMatches[1]);
              if (!isNaN(d.getTime())) {
                return d;
              }
            }
            
            // If ISO format doesn't match, try month year format ("April 2025")
            const monthYearMatches = eventDate.match(/(\w+)\s+(\d{4})/);
            if (monthYearMatches && monthYearMatches.length >= 3) {
              const month = ['january', 'february', 'march', 'april', 'may', 'june', 
                        'july', 'august', 'september', 'october', 'november', 'december']
                        .indexOf(monthYearMatches[1].toLowerCase());
              const year = parseInt(monthYearMatches[2]);
              
              if (month !== -1 && !isNaN(year)) {
                // Set to the first day of the specified month and year
                return new Date(year, month, 1);
              }
            }
            
            return result;
          }
          
          // For approximate dates or date ranges (with slashes)
          if (eventDate.includes('/') || eventDate.toLowerCase().includes('approx')) {
            // Extract the first date if it's a range
            let dateStr = eventDate;
            if (eventDate.includes('/')) {
              dateStr = eventDate.split('/')[0].trim();
            }
            
            // Remove any "Approx." prefix
            dateStr = dateStr.replace(/approx\.\s*/i, '').trim();
            
            const d = new Date(dateStr);
            return isNaN(d.getTime()) ? result : d;
          }
        }
        
        // Standard date parsing
        const d = new Date(eventDate);
        return isNaN(d.getTime()) ? result : d;
      }
      
      const dateA = getComparableDate(a.date);
      const dateB = getComparableDate(b.date);
      
      // Put past events at the end
      const isPastA = dateA < today && dateA.getFullYear() !== 9999;
      const isPastB = dateB < today && dateB.getFullYear() !== 9999;
      
      if (isPastA && !isPastB) return 1;
      if (!isPastA && isPastB) return -1;
      
      // Sort by date (ascending)
      return dateA - dateB;
    })
    .slice(0, 3); // Take only first 3 events
});

// Add this new computed property in your script section
const topHolidays = computed(() => {
  // First apply any search filtering
  const filtered = filteredHolidays.value;
  
  // Sort by date (nearest first)
  return filtered
    .sort((a, b) => {
      // Implement the same date sorting logic we used for events
      const dateA = getSortableDate(a.date);
      const dateB = getSortableDate(b.date);
      
      // Today's date for comparison
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      // Put past holidays at the end
      const isPastA = dateA < today && dateA.getFullYear() !== 9999;
      const isPastB = dateB < today && dateB.getFullYear() !== 9999;
      
      if (isPastA && !isPastB) return 1;
      if (!isPastA && isPastB) return -1;
      
      // Sort by date (ascending)
      return dateA - dateB;
    })
    .slice(0, 4); // Take only first 4 holidays
});

// You'll need this function for date sorting
function getSortableDate(dateStr) {
  if (!dateStr || typeof dateStr !== 'string' || 
      dateStr.toLowerCase().includes('tbc') || 
      dateStr.toLowerCase().includes('to be confirmed') || 
      dateStr.toLowerCase().includes('tba')) {
    return new Date('9999-12-31'); // Far future for TBC dates
  }
  
  const d = new Date(dateStr);
  return isNaN(d.getTime()) ? new Date('9999-12-31') : d;
}

// Navigation functions
function viewEventDetails(eventId) {
  router.push({ name: 'event-details', params: { id: eventId } });
}

function viewAttractionDetails(attractionId) {
  router.push({ name: 'attraction-details', params: { id: attractionId } });
}

function viewHolidayDetails(holidayId) {
  router.push({ name: 'holiday-details', params: { id: holidayId } });
}

// Slider functions
function nextSlide() {
  currentSlide.value = (currentSlide.value + 1) % totalSlides;
}

function prevSlide() {
  currentSlide.value = (currentSlide.value - 1 + totalSlides) % totalSlides;
}

function setSlide(index) {
  currentSlide.value = index;
}

// Start auto-sliding
function startAutoSlide() {
  stopAutoSlide(); // Clear any existing interval first
  autoSlideInterval.value = setInterval(() => {
    nextSlide();
  }, 5000); // Change slide every 5 seconds
}

// Stop auto-sliding
function stopAutoSlide() {
  if (autoSlideInterval.value) {
    clearInterval(autoSlideInterval.value);
    autoSlideInterval.value = null;
  }
}

// Setup auto-sliding on mount
onMounted(async () => {
  isLoading.value = true;
  try {
    // Debug: Check all dates in events data
    eventsData.forEach(event => {
      try {
        const d = new Date(event.date);
        if (isNaN(d.getTime())) {
          console.error(`Invalid date in event "${event.title}": ${event.date}`);
        }
      } catch (e) {
        console.error(`Error parsing date in event "${event.title}": ${event.date}`, e);
      }
    });
    
    // Load data from imported JSON
    setTimeout(() => {
      upcomingEvents.value = eventsData;
      localAttractions.value = attractionsData;
      upcomingHolidays.value = holidaysData;
      isLoading.value = false;
    }, 500); // Small timeout to simulate loading
  } catch (error) {
    console.error('Failed to fetch data:', error);
    isLoading.value = false;
  }
  
  // Start the auto slider
  startAutoSlide();
});

// Clean up interval on unmount
onUnmounted(() => {
  stopAutoSlide();
});

// Stop auto-sliding when user interacts with slider
function userInteracted() {
  stopAutoSlide();
  // Restart after a period of inactivity
  setTimeout(() => {
    startAutoSlide();
  }, 10000); // Restart after 10 seconds of inactivity
}

// Use this handler in the template where needed
function handleSlideClick(index) {
  setSlide(index);
  userInteracted();
}

// Similarly for prev/next
function handlePrevClick() {
  prevSlide();
  userInteracted();
}

function handleNextClick() {
  nextSlide();
  userInteracted();
}
</script> 